// 👻 Developed by DanBi Choi on Aug 11th, 2023.
// -----------------------------------------------------

@import "../../App.scss";

.post-box {
    .img-box {
        width: 100%;
        height: 364px;
        border-radius: 10px;
        background-color: $background-color-gray;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            height: 200px;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .post-info {
        margin: 60px 0;
        h1 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        h3 {
            color: $color-gray;
            line-height: 160%;
            font-size: $desktop-font-small;
        }
    }

    p {
        color: $color-gray;
        line-height: 160%;
        font-size: $desktop-font-small;
    }
}

.post-comment-box {
    margin-bottom: 100px;

    .comment-write {
        width: 100%;
        background-color: $background-color-gray;
        border-radius: 10px;
        padding: 37px 22px;

        h3 {
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: 500;
            line-height: 160%;
        }

        input,
        textarea {
            margin-bottom: 15px;
            background-color: $color-white;
        }

        button {
            font-size: $desktop-font-small;
            font-weight: 600;
            line-height: 160%;
        }
    }

    .post-comment-list {
        margin-bottom: 100px;
    }
}

.post-comment-list {
    h3 {
        font-weight: 600;
        font-size: $desktop-font-large;
        margin-bottom: 30px;
        color: $color-black;
    }

    li {
        margin-bottom: 30px;
        line-height: 130%;
        font-weight: 400;
        background-color: $background-color-gray;
        border-radius: 10px;
        padding: 22px;

        h4 {
            font-size: $desktop-font-medium;
            font-weight: 500;
        }

        h5 {
            color: $color-gray;
            font-size: 12px;
        }
        p {
            margin-top: 12px;
            color: $color-gray;
            font-size: $desktop-font-small;
            line-height: 160%;
        }
    }
}
