// 👻 Developed by DanBi Choi on Aug 14th, 2023.
// -----------------------------------------------------

@import "../App.scss";
@import "../pages/Home.scss";

.about-box {
    margin: 75px 0 0 0;
    @include mobile-center;

    .col-md-4 {
        .img-box {
            width: 100%;
            max-width: 300px;
            height: 100%;
            max-height: 300px;
            transition: 0.5s ease-in-out;

            @include mobile {
                max-width: 200px;
                max-height: 200px;
                margin-bottom: 1.5rem;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }

    .col-md-8 {
        @include mobile-center;

        h1 {
            font-size: $desktop-font-xlarge;
            font-weight: 600;
            line-height: 160%;
            @include mobile {
                font-size: $mobile-font-xlarge;
            }

            span {
                font-size: $desktop-font-large;
                font-weight: 600;
                @include mobile {
                    font-size: $mobile-font-large;
                }
            }
        }
        h5 {
            font-size: $desktop-font-small;
            line-height: 160%;
        }
        p {
            margin: 20px 0;
            color: $color-gray;
            font-size: $desktop-font-small;
            line-height: 170%;
            @include mobile {
                padding: 0 15px;
                text-align: center;
                font-size: $mobile-font-small;
            }
        }
    }
}
