// 👻 Developed by DanBi Choi on July 25th, 2023.
// -----------------------------------------------------
@import "../../App.scss";

.card-container {
    width: 100%;
    height: 240px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 5px 30px 0px rgba(219, 219, 219, 0.3);
    display: flex;
    flex-direction: row;
    align-items: space-between;
    margin-bottom: 40px;

    @include mobile {
        flex-direction: column;
        height: 430px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
            rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .img {
        width: 35%;
        max-width: 270px;
        height: 100%;
        border-radius: 10px 0px 0px 10px;
        cursor: pointer;
        @include mobile {
            width: 100%;
            height: 200px;
            max-width: none;
            border-radius: 10px 10px 0px 0px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 0px 0px 10px;
            @include mobile {
                border-radius: 10px 10px 0px 0px;
            }
        }
    }

    .text {
        padding: 22px;
        width: 100%;

        .text-bottom {
            border-top: 1px solid rgba(157, 155, 155, 0.2);
            padding-top: 12px;
        }

        h2 {
            font-size: 12px;
            padding: 5px 10px;
            color: $color-white;
            background-color: $color-yellow;
            border-radius: 5px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        h3 {
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 9px;
            cursor: pointer;
        }

        h5 {
            line-height: 170%;
            color: $color-gray;
            margin-bottom: 20px;
            cursor: pointer;
        }

        h4 {
            margin: 4px 0 0 0;
            font-weight: 600;
            line-height: 130%;
            font-size: $desktop-font-large;
            color: $color-purple;
        }

        h6 {
            font-size: 13px;
            font-weight: 500;
            line-height: 130%;
            margin: 0 5px 0 0;

            &:nth-child(2) {
                margin-bottom: 2px;
            }

            &:last-child {
                margin-right: 20px;
            }
        }

        .btn-primary {
            font-size: 14px;
            font-weight: 500;
            line-height: 130%;
            padding: 8px 13px;
        }
    }

    .blog-text {
        .text-bottom {
            border-top: 1px solid rgba(157, 155, 155, 0.2);
            padding-top: 15px;
        }
        h4 {
            margin-top: 0;
            font-weight: 500;
            font-size: 15px;
            color: $color-gray;
        }

        p {
            color: $color-gray;
            font-weight: 400;
            line-height: 100%;
            margin: 0;
        }
    }
}
