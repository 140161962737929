// 👻 Developed by DanBi Choi on Aug 16th, 2023.
// -----------------------------------------------------

@import "../../App.scss";

.profile-box {
    padding: 35px;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0px 5px 30px 0px rgba(219, 219, 219, 0.3);

    .profile-box-title {
        margin-bottom: 23px;
        h1 {
            font-size: $desktop-font-large;
            font-weight: 600;
        }

        h5 {
            font-size: $desktop-font-small;
            span {
                color: $color-purple;
            }
        }
    }

    li {
        display: grid;
        gap: 10px;
        align-items: center;
        margin: 10px 0;

        &.single {
            grid-template-columns: auto 1fr;
        }

        &.double {
            grid-template-columns: 1fr 2fr 1fr 2fr;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 10px 0;
        }

        label {
            font-size: $desktop-font-medium;
            font-weight: 500;
            width: 150px;
            @include mobile {
                font-size: $mobile-font-medium;
            }

            span {
                color: $color-purple;
            }
        }

        input {
            width: 100%;
            @include mobile {
                font-size: $mobile-font-medium;
                padding: 15px;
            }
        }

        select {
            border: none;
            background-color: $background-color-gray;
            color: $color-black;
            border-radius: 10px;
            padding: 20px;
            font-size: $desktop-font-small;
            cursor: pointer;
            width: 100%;

            @include mobile {
                font-size: $mobile-font-medium;
                padding: 15px;
            }
        }
    }

    .pw-list {
        margin: 8px 0 20px 0;

        li {
            list-style-type: disc;
            list-style-position: outside;
            color: $color-gray;
            font-size: $desktop-font-small;
            font-weight: 400;
        }
    }
}
