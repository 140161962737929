// 👻 Developed by DanBi Choi on July 24th, 2023.
// -----------------------------------------------------
@import "../App.scss";

.input-container {
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 0px 5px 30px 0px rgba(219, 219, 219, 0.3);
    padding: 40px;

    h1 {
        font-size: $desktop-font-xlarge;
        font-weight: 600;
    }

    h5 {
        font-size: 15px;
        color: $color-gray;
        margin-top: 15px;
        line-height: 160%;
    }
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 500px;
        width: 100%;

        @include mobile {
            display: none;
        }
    }
}
