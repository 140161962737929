// 👻 Developed by DanBi Choi on July 29th, 2023.
// -----------------------------------------------------

@import "../App.scss";

.cart-box {
    margin: 75px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .goShopBtn {
        margin-top: 50px;
        width: 120px;
        font-size: $desktop-font-small;
        padding: 10px 15px;
    }

    table {
        width: 100%;

        tr {
            border-bottom: 1px solid #ebebeb;
            height: 180px;
            display: grid;
            grid-template-columns: 45% 15% 15% 15% 10%;
            text-align: center;
            @include mobile {
                grid-template-columns: 35% 20% 20% 20% 5%;
            }

            &:nth-child(1) {
                height: 30px;
                padding: 30px 0;
            }

            th {
                color: $color-gray;
                font-size: $desktop-font-medium;
                @include mobile {
                    font-size: $mobile-font-medium;
                }
            }

            td {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 30px 0;
            }

            .product-info {
                justify-content: flex-start;
                align-items: stretch;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;

                    .img {
                        width: 100%;
                        max-width: 100px;
                        height: 100%;
                        max-height: 120px;
                        @include mobile {
                            display: none;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            object-fit: cover;
                        }
                    }
                }

                h3 {
                    margin-left: 20px;
                    font-weight: 500;
                    font-size: $desktop-font-large;
                    @include mobile {
                        font-size: $mobile-font-medium;
                        margin-left: 0;
                    }
                }
            }

            h4 {
                font-weight: 500;
                font-size: $desktop-font-large;
                @include mobile {
                    font-size: $mobile-font-medium;
                }

                span {
                    color: $color-purple;
                    font-weight: 500;
                    font-size: $desktop-font-large;
                    @include mobile {
                        font-size: $mobile-font-medium;
                    }
                }
            }

            .td-quantity {
                .control-box {
                    width: 65px;
                    height: 40px;
                    border-radius: 10px;
                    background-color: $background-color-gray;
                    @include mobile {
                        width: 40px;
                        height: 40px;
                    }
                    h4 {
                        font-size: $desktop-font-medium;
                        margin-bottom: 0;
                        @include mobile {
                            font-size: $mobile-font-medium;
                        }
                    }
                }

                .arrow-box {
                    margin-left: 5px;
                    color: $color-gray;
                    @include mobile {
                        margin-left: 4px;
                    }

                    .arrow-btn {
                        border: none;
                        background-color: transparent;
                        padding: 0 10px;
                        @include mobile {
                            padding: 0 3px;
                        }

                        &:disabled {
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .delete-btn {
                border: none;
                background-color: transparent;
                padding: 3px;
            }
        }
    }
}
