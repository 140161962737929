// 👻 Developed by DanBi Choi on July 19th, 2023.
// -----------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

// --------------------------------------------------------
// 👉 Sass Variables
$font-main: 'Inter', sans-serif;
$font-text: 'Inter', sans-serif;
$color-purple: #7b1fa2;
$color-white: #ffffff;
$color-black: #221714;
$color-yellow: #ffbf35;
$color-gray: #706866;
$background-color-gray: #f4f5f6;
$background-color-beige: #f6f4ee;
$background-color-white: #fcfcfc;
$mobile-font-small: 12px;
$mobile-font-medium: 14px;
$mobile-font-large: 18px;
$mobile-font-xlarge: 28px;
$tablet-font-small: 14px;
$tablet-font-medium: 16px;
$tablet-font-large: 20px;
$tablet-font-xlarge: 32px;
$desktop-font-small: 14px;
$desktop-font-medium: 16px;
$desktop-font-large: 20px;
$desktop-font-xlarge: 32px;

// --------------------------------------------------------
// 👉 Responsive Size Setting
@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1023px) {
        @content;
    }
}

@mixin mobile-center {
    @include mobile {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @content;
    }
}

// --------------------------------------------------------
// 👉 Begin common css styles
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-text;
    list-style: none;
    font-size: $desktop-font-small;
    font-weight: 400;
    line-height: 130%;
}

.btn-primary {
    border: none;
    border-radius: 5px;
    background-color: $color-purple;
    color: $color-white;
    font-size: $desktop-font-medium;
    font-weight: 600;
    padding: 12px 30px;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: #481160 !important;
    }
}

.btn-danger {
    border: none;
    border-radius: 5px;
    background-color: #ce374a;
    color: $color-white;
    font-size: $desktop-font-medium;
    font-weight: 600;
    padding: 12px 30px;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: #aa2c44 !important;
    }
}

.btn-outline-primary {
    border: 1px solid $color-purple;
    border-radius: 5px;
    background-color: transparent;
    color: $color-purple;
    font-size: $desktop-font-medium;
    font-weight: 600;
    padding: 12px 30px;
    cursor: pointer;

    &:hover,
    &:active {
        border: 1px solid $color-purple !important;
        color: $color-purple !important;
        background-color: $color-white !important;
    }
}

.btn-secondary {
    border: none;
    border-radius: 5px;
    background-color: $background-color-gray;
    color: $color-black;
    font-size: $desktop-font-medium;
    font-weight: 600;
    padding: 12px 30px;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: #e2e2e2 !important;
        color: $color-black !important;
    }
}

.btn-outline-secondary {
    border: 1px solid $color-gray;
    border-radius: 5px;
    background-color: transparent;
    color: $color-gray;
    font-size: $desktop-font-medium;
    font-weight: 600;
    padding: 12px 30px;
    cursor: pointer;

    &:hover,
    &:active {
        color: $color-black !important;
        background-color: $color-white !important;
    }
}

input {
    border: none;
    background-color: $background-color-gray;
    color: $color-black;
    border-radius: 10px;
    padding: 20px;
    font-size: $desktop-font-small;
}

input:focus {
    outline: none;
    caret-color: $color-purple;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    color: $color-black;
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

body {
    background-color: $background-color-white;
    overflow-x: hidden;
}

::selection {
    background-color: $color-yellow;
    color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // font-weight: 400;
    margin: 0;
}

.slick-next::before,
.slick-prev::before {
    color: $color-purple;
}

/*! Firefox */
html {
    scrollbar-color: $background-color-gray $color-gray;
}
/*! Other Browser */
body::-webkit-scrollbar {
    width: 11px;
}
body {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: $color-gray $background-color-gray;
}
body::-webkit-scrollbar-track {
    background: $background-color-gray;
}
body::-webkit-scrollbar-thumb {
    background-color: $color-gray;
    border-radius: 6px;
    border: 3px solid $background-color-gray;
}

body.hide-scrollbar::-webkit-scrollbar {
    width: 0.1em;
}

body.hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.scrollbarDesign::-webkit-scrollbar {
    width: 11px;
}
.scrollbarDesign::-webkit-scrollbar-track {
    background: $background-color-beige;
}
.scrollbarDesign::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-radius: 6px;
    border: 3px solid $background-color-beige;
}

// End common css styles : added by Danbi on July 18th, 2023
