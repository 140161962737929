// 👻 Developed by DanBi Choi on July 19th, 2023.
// -----------------------------------------------------

@import "../App.scss";

.landing-view {
    padding: 150px 0 100px 0;
    @include mobile-center;
    @include mobile {
        padding: 50px 0 50px 0;
    }

    .hero {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .hero-welcome-text {
        opacity: 0;
        animation: fadeInUp 1.5s ease forwards;

        @include tablet {
            width: 50vw;
        }
        @include mobile-center;
        @include mobile {
            margin: auto;
        }
    }

    h1 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 38px;
        max-width: 562px;
        @include mobile {
            font-size: 32px;
        }
    }

    p {
        font-size: $desktop-font-medium;
        margin-bottom: 33px;
        color: $color-gray;
        max-width: 552px;
        line-height: 160%;
        @include mobile {
            font-size: $mobile-font-medium;
        }
    }

    h5 {
        font-size: $desktop-font-medium;
        color: $color-purple;
        font-weight: 600;
        margin-bottom: 33px;
        line-height: 160%;
        @include mobile {
            font-size: $mobile-font-medium;
        }
    }

    .hero-image-container {
        position: relative;
        width: 50vw;
        max-width: 500px;

        @include mobile {
            display: none;
        }
    }

    .hero-img {
        width: 65%;
        height: auto;
        border-radius: 20px;
        border: 1px solid rgba($color-gray, 0.2);
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: transform 0.5s ease-in-out;
        opacity: 0;
        position: absolute;

        &:nth-child(1) {
            top: 20vh;
            right: 0;
            z-index: 3;
            transform: scale(0.95);
            animation: fadeIn2 1.5s forwards;

            @include tablet {
                top: 20vh;
                right: 0;
            }

            &:hover {
                transform: scale(1.05);
            }
        }

        &:nth-child(2) {
            top: 0;
            right: 0;
            z-index: 2;
            transform: scale(1);
            animation: fadeIn 1.5s forwards;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

@keyframes fadeIn {
    from {
        top: 5%;
        right: 5%;
    }
    to {
        top: 0;
        right: 0;
        opacity: 1;
    }
}

@keyframes fadeIn2 {
    from {
        top: 15vh;
        left: 5%;
    }
    to {
        top: 20vh;
        left: 0;
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.customerTypes {
    .card-box {
        width: 100%;
        padding: 40px 31px 40px 31px;
        border-radius: 10px;
        background: $color-white;
        box-shadow: 0px 5px 20px 0px rgba(254, 230, 237, 0.2);
        line-height: 160%;

        h3 {
            padding-top: 14px;
            font-size: $desktop-font-large;
            font-weight: 600;
            margin-bottom: 0;
            @include mobile {
                font-size: $mobile-font-large;
            }
        }

        h4 {
            color: $color-white;
            font-size: $desktop-font-large;
            font-weight: 600;
            @include mobile {
                font-size: $mobile-font-large;
            }
        }

        p {
            margin: 14px 0 20px 0;
            color: $color-white;
            font-size: $desktop-font-small;
            @include mobile {
                font-size: $mobile-font-medium;
            }
        }

        &:hover {
            background-color: $color-purple;
        }

        button {
            border: 1px solid #fff;
            color: #fff;
            width: 160px;
            font-weight: 500;
            font-size: $desktop-font-small;
            border-radius: 10px;
            padding: 8px 10px;
            @include mobile {
                font-size: $mobile-font-small;
                padding: 8px 10px;
            }
        }
    }
}

.creatorStory {
    text-align: center;
    .story-box {
        background-color: $background-color-beige;
        padding: 45px 0;

        img {
            width: 250px;
            height: 250px;
            border-radius: 50%;
            object-fit: cover;
        }

        h5 {
            font-weight: 600;
            font-size: 22px;
            line-height: 160%;
            @include mobile-center;
            @include mobile {
                font-size: $mobile-font-large;
            }
        }
        p {
            margin: 10px 0 40px 0;
            color: $color-gray;
            font-size: $desktop-font-medium;
            line-height: 160%;
            max-width: 670px;
            @include mobile-center;
            @include mobile {
                font-size: $mobile-font-medium;
            }
        }
        .link-button {
            @include mobile-center;
        }
    }
}

.review-card-col {
    transition: transform 0.5s ease-in-out;
    &:hover {
        transform: translateY(-15px);
    }
}

.worksheet {
    .worksheet-box {
        .col-md-6 {
            height: 285px;
            margin-bottom: 10px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}

.customerTypes {
    .slick-slider {
        padding: 0;
    }

    .slick-next::before,
    .slick-prev::before {
        color: $background-color-beige;
    }
}
